import { ConstructionProjectDto } from '@api';
import { ADDRESSES } from './address';
import { CUSTOMERS } from '@app/mock/data/customers';

const baseConstructionProject: ConstructionProjectDto = {
  id: null,
  lastChange: null,
  created: null,
  name: '',
  comment: '',
  distance: 0,
  address: ADDRESSES[0],
  customer: CUSTOMERS[0],
  customerId: CUSTOMERS[0].id || 0,
  articlePrices: [],
  vehicleTypePrices: [],
  isClosed: false
};

// https://next.json-generator.com/NJP6yYmm_
export const CONSTRUCTION_PROJECTS: ConstructionProjectDto[] = [
  {
    ...baseConstructionProject,
    id: 50,
    name: 'ea ea irure',
    comment: 'Exercitation id aliqua et eu consequat quis sunt eu deserunt dolor incididunt ullamco.',
    address: ADDRESSES[0],
    created: '2020-02-12T08:16:59.678Z',
    lastChange: '2020-02-12T08:16:59.678Z'
  },
  {
    ...baseConstructionProject,
    id: 1,
    name: 'adipisicing aute nostrud',
    comment: 'Tempor officia ea non laborum quis cillum sunt veniam commodo culpa culpa mollit aute.',
    address: ADDRESSES[1],
    created: '2020-02-12T08:16:59.678Z',
    lastChange: '2020-02-12T08:16:59.678Z'
  },
  {
    ...baseConstructionProject,
    id: 2,
    name: 'enim pariatur sit',
    comment: 'Quis sint aliqua voluptate Lorem adipisicing.',
    address: ADDRESSES[2],
    created: '2020-02-12T08:16:59.678Z',
    lastChange: '2020-02-12T08:16:59.678Z'
  },
  {
    ...baseConstructionProject,
    id: 3,
    name: 'do cupidatat consectetur',
    comment: 'Lorem exercitation aute elit elit enim reprehenderit adipisicing esse adipisicing aute mollit eu qui.',
    address: ADDRESSES[3],
    created: '2020-02-12T08:16:59.678Z',
    lastChange: '2020-02-12T08:16:59.678Z'
  },
  {
    ...baseConstructionProject,
    id: 4,
    name: 'est incididunt voluptate',
    comment: 'Anim magna adipisicing reprehenderit eiusmod id exercitation culpa amet ipsum duis.',
    address: ADDRESSES[4],
    created: '2020-02-12T08:16:59.678Z',
    lastChange: '2020-02-12T08:16:59.678Z'
  },
  {
    ...baseConstructionProject,
    id: 5,
    name: 'nulla cupidatat occaecat',
    comment: 'Culpa magna voluptate aliqua ullamco aute in proident magna amet elit magna.',
    address: ADDRESSES[5],
    created: '2020-02-12T08:16:59.678Z',
    lastChange: '2020-02-12T08:16:59.678Z'
  },
  {
    ...baseConstructionProject,
    id: 6,
    name: 'dolore aliquip ad',
    comment: 'Magna dolor in cillum ad incididunt laborum in.',
    address: ADDRESSES[6],
    created: '2020-02-12T08:16:59.678Z',
    lastChange: '2020-02-12T08:16:59.678Z'
  },
  {
    ...baseConstructionProject,
    id: 7,
    name: 'labore id ea',
    comment: 'Quis ullamco aliquip reprehenderit dolor irure do consectetur laboris nostrud officia eu sint velit enim.',
    address: ADDRESSES[7],
    created: '2020-02-12T08:16:59.678Z',
    lastChange: '2020-02-12T08:16:59.678Z'
  },
  {
    ...baseConstructionProject,
    id: 8,
    name: 'excepteur incididunt do',
    comment: 'Amet consectetur ipsum est irure dolor consequat qui voluptate fugiat reprehenderit reprehenderit.',
    address: ADDRESSES[8],
    created: '2020-02-12T08:16:59.678Z',
    lastChange: '2020-02-12T08:16:59.678Z'
  },
  {
    ...baseConstructionProject,
    id: 9,
    name: 'ex mollit magna',
    comment: 'Officia aute quis do laboris aliquip excepteur minim.',
    address: ADDRESSES[9],
    created: '2020-02-12T08:16:59.678Z',
    lastChange: '2020-02-12T08:16:59.678Z'
  },
  {
    ...baseConstructionProject,
    id: 10,
    name: 'reprehenderit quis ea',
    comment: 'Ipsum laboris quis consectetur ex elit labore enim.',
    address: ADDRESSES[10],
    created: '2020-02-12T08:16:59.678Z',
    lastChange: '2020-02-12T08:16:59.678Z'
  },
  {
    ...baseConstructionProject,
    id: 11,
    name: 'aliquip duis non',
    comment: 'Laboris pariatur eu deserunt pariatur quis laboris adipisicing est qui consectetur mollit dolor.',
    address: ADDRESSES[11],
    created: '2020-02-12T08:16:59.678Z',
    lastChange: '2020-02-12T08:16:59.678Z'
  },
  {
    ...baseConstructionProject,
    id: 12,
    name: 'veniam proident aute',
    comment: 'Qui consequat ad esse mollit aliquip esse dolor ut esse voluptate dolor cupidatat.',
    address: ADDRESSES[12],
    created: '2020-02-12T08:16:59.678Z',
    lastChange: '2020-02-12T08:16:59.678Z'
  },
  {
    ...baseConstructionProject,
    id: 13,
    name: 'eiusmod sunt sit',
    comment: 'Voluptate excepteur exercitation ex non cupidatat veniam cupidatat elit.',
    address: ADDRESSES[13],
    created: '2020-02-12T08:16:59.678Z',
    lastChange: '2020-02-12T08:16:59.678Z'
  },
  {
    ...baseConstructionProject,
    id: 14,
    name: 'nulla id laborum',
    comment: 'Labore adipisicing aliquip ea enim enim.',
    address: ADDRESSES[14],
    created: '2020-02-12T08:16:59.678Z',
    lastChange: '2020-02-12T08:16:59.678Z'
  },
  {
    ...baseConstructionProject,
    id: 15,
    name: 'proident culpa fugiat',
    comment: 'Consectetur ad ad consectetur amet pariatur.',
    address: ADDRESSES[15],
    created: '2020-02-12T08:16:59.678Z',
    lastChange: '2020-02-12T08:16:59.678Z'
  },
  {
    ...baseConstructionProject,
    id: 16,
    name: 'irure duis duis',
    comment: 'Aliqua ut ullamco labore est duis ipsum.',
    address: ADDRESSES[16],
    created: '2020-02-12T08:16:59.678Z',
    lastChange: '2020-02-12T08:16:59.678Z'
  },
  {
    ...baseConstructionProject,
    id: 17,
    name: 'id ad ad',
    comment: 'Proident non aliquip reprehenderit elit do consequat ex consectetur sunt consectetur eiusmod.',
    address: ADDRESSES[17],
    created: '2020-02-12T08:16:59.678Z',
    lastChange: '2020-02-12T08:16:59.678Z'
  },
  {
    ...baseConstructionProject,
    id: 18,
    name: 'sint cupidatat pariatur',
    comment: 'Nisi qui nisi laboris dolor aliquip voluptate sunt pariatur ad do eiusmod laboris pariatur.',
    address: ADDRESSES[18],
    created: '2020-02-12T08:16:59.678Z',
    lastChange: '2020-02-12T08:16:59.678Z'
  },
  {
    ...baseConstructionProject,
    id: 19,
    name: 'excepteur esse sint',
    comment: 'Laboris consectetur ipsum ut laboris aliqua reprehenderit anim.',
    address: ADDRESSES[19],
    created: '2020-02-12T08:16:59.678Z',
    lastChange: '2020-02-12T08:16:59.678Z'
  },
  {
    ...baseConstructionProject,
    id: 20,
    name: 'velit pariatur veniam',
    comment: 'Laboris nulla culpa sunt consectetur in laborum ea nisi occaecat officia est.',
    address: ADDRESSES[20],
    created: '2020-02-12T08:16:59.678Z',
    lastChange: '2020-02-12T08:16:59.678Z'
  },
  {
    ...baseConstructionProject,
    id: 21,
    name: 'ex dolor enim',
    comment: 'Id nostrud excepteur occaecat irure pariatur enim veniam nostrud ea.',
    address: ADDRESSES[21],
    created: '2020-02-12T08:16:59.678Z',
    lastChange: '2020-02-12T08:16:59.678Z'
  },
  {
    ...baseConstructionProject,
    id: 22,
    name: 'mollit labore in',
    comment: 'Amet consequat anim laboris est dolor.',
    address: ADDRESSES[22],
    created: '2020-02-12T08:16:59.678Z',
    lastChange: '2020-02-12T08:16:59.678Z'
  },
  {
    ...baseConstructionProject,
    id: 23,
    name: 'culpa nostrud mollit',
    comment: 'Cillum Lorem irure aliquip excepteur.',
    address: ADDRESSES[23],
    created: '2020-02-12T08:16:59.678Z',
    lastChange: '2020-02-12T08:16:59.678Z'
  },
  {
    ...baseConstructionProject,
    id: 24,
    name: 'eu amet ad',
    comment: 'Nulla enim proident qui amet officia Lorem ullamco aute consectetur voluptate tempor.',
    address: ADDRESSES[24],
    created: '2020-02-12T08:16:59.678Z',
    lastChange: '2020-02-12T08:16:59.678Z'
  },
  {
    ...baseConstructionProject,
    id: 25,
    name: 'occaecat voluptate et',
    comment: 'Proident Lorem reprehenderit tempor aliquip.',
    address: ADDRESSES[25],
    created: '2020-02-12T08:16:59.678Z',
    lastChange: '2020-02-12T08:16:59.678Z'
  },
  {
    ...baseConstructionProject,
    id: 26,
    name: 'nostrud in elit',
    comment: 'Adipisicing laboris elit ut fugiat reprehenderit ex aliqua duis laboris.',
    address: ADDRESSES[26],
    created: '2020-02-12T08:16:59.678Z',
    lastChange: '2020-02-12T08:16:59.678Z'
  },
  {
    ...baseConstructionProject,
    id: 27,
    name: 'aute proident exercitation',
    comment: 'Duis voluptate officia irure ad.',
    address: ADDRESSES[27],
    created: '2020-02-12T08:16:59.678Z',
    lastChange: '2020-02-12T08:16:59.678Z'
  },
  {
    ...baseConstructionProject,
    id: 28,
    name: 'aliquip nisi ullamco',
    comment: 'Dolor minim sunt sunt veniam duis elit fugiat fugiat nulla sint ut pariatur eu.',
    address: ADDRESSES[28],
    created: '2020-02-12T08:16:59.678Z',
    lastChange: '2020-02-12T08:16:59.678Z'
  },
  {
    ...baseConstructionProject,
    id: 29,
    name: 'cupidatat dolore excepteur',
    comment: 'Aliqua exercitation et laboris aliqua tempor labore labore.',
    address: ADDRESSES[29],
    created: '2020-02-12T08:16:59.678Z',
    lastChange: '2020-02-12T08:16:59.678Z'
  },
  {
    ...baseConstructionProject,
    id: 30,
    name: 'consectetur proident occaecat',
    comment: 'Quis quis aliquip pariatur incididunt dolor reprehenderit consequat velit culpa dolore cupidatat eu sunt.',
    address: ADDRESSES[30],
    created: '2020-02-12T08:16:59.678Z',
    lastChange: '2020-02-12T08:16:59.678Z'
  },
  {
    ...baseConstructionProject,
    id: 31,
    name: 'fugiat eiusmod minim',
    comment: 'Laborum duis nulla deserunt irure duis amet dolor.',
    address: ADDRESSES[31],
    created: '2020-02-12T08:16:59.678Z',
    lastChange: '2020-02-12T08:16:59.678Z'
  },
  {
    ...baseConstructionProject,
    id: 32,
    name: 'amet Lorem consequat',
    comment: 'Adipisicing sit exercitation laborum id commodo incididunt velit adipisicing do et eiusmod enim commodo duis.',
    address: ADDRESSES[32],
    created: '2020-02-12T08:16:59.678Z',
    lastChange: '2020-02-12T08:16:59.678Z'
  },
  {
    ...baseConstructionProject,
    id: 33,
    name: 'fugiat reprehenderit commodo',
    comment: 'Deserunt qui dolor ullamco anim laborum aliquip ut amet ullamco incididunt voluptate.',
    address: ADDRESSES[33],
    created: '2020-02-12T08:16:59.678Z',
    lastChange: '2020-02-12T08:16:59.678Z'
  },
  {
    ...baseConstructionProject,
    id: 34,
    name: 'aliquip occaecat eu',
    comment: 'Magna sit esse sunt ipsum anim magna nisi ipsum eu ex occaecat sunt tempor quis.',
    address: ADDRESSES[34],
    created: '2020-02-12T08:16:59.678Z',
    lastChange: '2020-02-12T08:16:59.678Z'
  },
  {
    ...baseConstructionProject,
    id: 35,
    name: 'incididunt eu nostrud',
    comment: 'Mollit eiusmod proident pariatur ipsum laboris in eiusmod eiusmod.',
    address: ADDRESSES[35],
    created: '2020-02-12T08:16:59.678Z',
    lastChange: '2020-02-12T08:16:59.678Z'
  },
  {
    ...baseConstructionProject,
    id: 36,
    name: 'laborum amet sunt',
    comment: 'In ut minim ipsum quis culpa ea anim dolore ex.',
    address: ADDRESSES[36],
    created: '2020-02-12T08:16:59.678Z',
    lastChange: '2020-02-12T08:16:59.678Z'
  },
  {
    ...baseConstructionProject,
    id: 37,
    name: 'quis irure consequat',
    comment: 'Ullamco ut velit velit irure aute ipsum sint.',
    address: ADDRESSES[37],
    created: '2020-02-12T08:16:59.678Z',
    lastChange: '2020-02-12T08:16:59.678Z'
  },
  {
    ...baseConstructionProject,
    id: 38,
    name: 'sunt consequat ad',
    comment: 'Tempor sint eu ea laboris magna velit veniam consequat eu aliquip labore.',
    address: ADDRESSES[38],
    created: '2020-02-12T08:16:59.678Z',
    lastChange: '2020-02-12T08:16:59.678Z'
  },
  {
    ...baseConstructionProject,
    id: 39,
    name: 'non dolor et',
    comment: 'Consectetur velit laboris nisi voluptate laborum sint nostrud adipisicing est aliquip nostrud ex irure dolore.',
    address: ADDRESSES[39],
    created: '2020-02-12T08:16:59.678Z',
    lastChange: '2020-02-12T08:16:59.678Z'
  },
  {
    ...baseConstructionProject,
    id: 40,
    name: 'et adipisicing excepteur',
    comment: 'Occaecat sint magna sit quis est eiusmod aliqua laboris voluptate.',
    address: ADDRESSES[40],
    created: '2020-02-12T08:16:59.678Z',
    lastChange: '2020-02-12T08:16:59.678Z'
  },
  {
    ...baseConstructionProject,
    id: 41,
    name: 'mollit aliqua id',
    comment: 'Ut velit incididunt laboris id incididunt mollit.',
    address: ADDRESSES[41],
    created: '2020-02-12T08:16:59.678Z',
    lastChange: '2020-02-12T08:16:59.678Z'
  },
  {
    ...baseConstructionProject,
    id: 42,
    name: 'laborum sit irure',
    comment: 'Ea mollit dolore id ex laboris laboris.',
    address: ADDRESSES[42],
    created: '2020-02-12T08:16:59.678Z',
    lastChange: '2020-02-12T08:16:59.678Z'
  },
  {
    ...baseConstructionProject,
    id: 43,
    name: 'amet ad ex',
    comment: 'Pariatur aliqua ad do sint ullamco adipisicing est non officia excepteur ex esse do reprehenderit.',
    address: ADDRESSES[43],
    created: '2020-02-12T08:16:59.678Z',
    lastChange: '2020-02-12T08:16:59.678Z'
  },
  {
    ...baseConstructionProject,
    id: 44,
    name: 'cupidatat Lorem qui',
    comment: 'Officia incididunt tempor enim in reprehenderit dolor officia non anim tempor labore laboris reprehenderit.',
    address: ADDRESSES[44],
    created: '2020-02-12T08:16:59.678Z',
    lastChange: '2020-02-12T08:16:59.678Z'
  },
  {
    ...baseConstructionProject,
    id: 45,
    name: 'aliquip ad elit',
    comment: 'Est ut do dolor in velit sit cupidatat.',
    address: ADDRESSES[45],
    created: '2020-02-12T08:16:59.678Z',
    lastChange: '2020-02-12T08:16:59.678Z'
  },
  {
    ...baseConstructionProject,
    id: 46,
    name: 'deserunt ex voluptate',
    comment: 'Occaecat magna sit amet ipsum ut ex reprehenderit occaecat elit do ut dolore.',
    address: ADDRESSES[46],
    created: '2020-02-12T08:16:59.678Z',
    lastChange: '2020-02-12T08:16:59.678Z'
  },
  {
    ...baseConstructionProject,
    id: 47,
    name: 'ipsum laborum mollit',
    comment: 'Laboris quis deserunt reprehenderit esse veniam aliquip irure magna minim elit voluptate laborum.',
    address: ADDRESSES[47],
    created: '2020-02-12T08:16:59.678Z',
    lastChange: '2020-02-12T08:16:59.678Z'
  },
  {
    ...baseConstructionProject,
    id: 48,
    name: 'occaecat esse amet',
    comment: 'Minim labore dolore anim mollit ex qui qui occaecat ut aliqua nostrud ullamco.',
    address: ADDRESSES[48],
    created: '2020-02-12T08:16:59.678Z',
    lastChange: '2020-02-12T08:16:59.678Z'
  },
  {
    ...baseConstructionProject,
    id: 49,
    name: 'in commodo ut',
    comment: 'Irure aliquip incididunt in mollit consectetur nisi ipsum quis laborum culpa.',
    address: ADDRESSES[49],
    created: '2020-02-12T08:16:59.678Z',
    lastChange: '2020-02-12T08:16:59.678Z'
  }
];
