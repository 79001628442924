import { ApiResponseOfApplicationSettingsDto, ApplicationSettingsDto, IApplicationSettingsApiService } from '@api';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { COUNTRIES } from '@app/mock/data/countries';

@Injectable()
export class ApplicationSettingsApiMockService implements IApplicationSettingsApiService {

  public static DEFAULT_SETTINGS: ApplicationSettingsDto = {
    defaultCountry: COUNTRIES[0],
    defaultCountryId: COUNTRIES[0].id,
    steelCalculationFactor: 10,
    receiptExternalCalculationFactorPercent: 15,
    receiptExternalCalculationMinimumDistance: 300,
    defaultPaymentTermDays: 14,
    externalCalculationPlateCount: 18,
    dueDateExtensionOnPostal: 2,
    warehouseLocation: 'Deutschland, 46395 Bocholt, Brinkstegge 37',
    replyNotificationEmail: 'hello@world.com'
  };

  private settings: ApplicationSettingsDto = ApplicationSettingsApiMockService.DEFAULT_SETTINGS;

  constructor() { }


  getApplicationSettings(): Observable<ApiResponseOfApplicationSettingsDto> {
    return of({
      success: true,
      data: this.settings,
      message: null,
      count: null,
      maxCount: null
    });
  }

  updateApplicationSettings(dto: ApplicationSettingsDto): Observable<ApiResponseOfApplicationSettingsDto> {
    this.settings = dto;
    return this.getApplicationSettings();
  }

}
