import { Injectable } from '@angular/core';
import { AddressDto, DistanceCalculationApiService } from '@api';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DistanceService {
  constructor(private api: DistanceCalculationApiService) {
  }

  calculateDistance(to: AddressDto): Observable<number | null> {
    return this.api.calculateDistance(to.zipCode, to.city, to.street, to.houseNumber, to.country?.name);
  }
}
