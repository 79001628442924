import { ErrorHandler, Injectable } from '@angular/core';
import * as Sentry from '@sentry/browser';
import { environment } from '@env';

@Injectable({
  providedIn: 'root'
})
export class SentryErrorHandlerService implements ErrorHandler {

  constructor() {
    Sentry.init({
      dsn: environment.sentry.dsn,
      environment: environment.sentry.environment,
      release: environment.version
    });
  }

  handleError(error: any): void {
    Sentry.captureException(error.originalError || error);
  }
}
