import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticationGuard } from '@core/authentication/guards/authentication.guard';
import { NotFoundComponent } from '@shared/components/not-found/not-found.component';
import { PermissionGuard } from '@shared/permissions/permission.guard';
import { ALL_EXPORT_PERMISSIONS, Permission } from '@shared/permissions';
import { NoPermissionsComponent } from './shared/components/no-permissions/no-permissions.component';

// these are the main routes of this application
const APP_ROUTES: Routes = [
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
    canLoad: [AuthenticationGuard],
    data: {
      hideInDashboard: true,
      title: 'DASHBOARD.TITLE',
      icon: 'DASHBOARD.ICON',
      permission: null,
      breadcrumb: {
        label: 'DASHBOARD.TITLE'
      }
    }
  },
  {
    path: 'contact-person',
    loadChildren: () => import('./contact-person/contact-person.module').then(m => m.ContactPersonModule),
    canLoad: [AuthenticationGuard, PermissionGuard],
    data: {
      title: 'CONTACT_PERSON.TITLE',
      icon: 'CONTACT_PERSON.ICON',
      description: 'CONTACT_PERSON.DESCRIPTION',
      permission: Permission.CONTACT_PERSONS_LOAD,
      breadcrumb: {
        label: 'CONTACT_PERSON.TITLE'
      }
    }
  },
  {
    path: 'article',
    loadChildren: () => import('./article/article.module').then(m => m.ArticleModule),
    canLoad: [AuthenticationGuard, PermissionGuard],
    data: {
      title: 'ARTICLE.TITLE',
      icon: 'ARTICLE.ICON',
      description: 'ARTICLE.DESCRIPTION',
      permission: Permission.ARTICLES_LOAD,
      breadcrumb: {
        label: 'ARTICLE.TITLE'
      }
    }
  },
  {
    path: 'construction-project',
    loadChildren: () => import('./construction-project/construction-project.module').then(m => m.ConstructionProjectModule),
    canLoad: [AuthenticationGuard, PermissionGuard],
    data: {
      title: 'CONSTRUCTION_PROJECT.TITLE',
      icon: 'CONSTRUCTION_PROJECT.ICON',
      description: 'CONSTRUCTION_PROJECT.DESCRIPTION',
      permission: Permission.CONSTRUCTION_PROJECTS_LOAD,
      breadcrumb: {
        label: 'CONSTRUCTION_PROJECT.TITLE'
      }
    }
  },
  {
    path: 'user',
    loadChildren: () => import('./user/user.module').then(m => m.UserModule),
    canLoad: [AuthenticationGuard, PermissionGuard],
    data: {
      title: 'USER.TITLE',
      icon: 'USER.ICON',
      description: 'USER.DESCRIPTION',
      permission: Permission.USER_LOAD,
      breadcrumb: {
        label: 'USER.TITLE'
      }
    }
  },
  {
    path: 'customer',
    loadChildren: () => import('./customer/customer.module').then(m => m.CustomerModule),
    canLoad: [AuthenticationGuard, PermissionGuard],
    data: {
      title: 'CUSTOMER.TITLE',
      icon: 'CUSTOMER.ICON',
      description: 'CUSTOMER.DESCRIPTION',
      permission: Permission.CUSTOMERS_LOAD,
      breadcrumb: {
        label: 'CUSTOMER.TITLE'
      }
    }
  },
  {
    path: 'delivery-condition',
    loadChildren: () => import('./delivery-condition/delivery-condition.module').then(m => m.DeliveryConditionModule),
    canLoad: [AuthenticationGuard, PermissionGuard],
    data: {
      title: 'DELIVERY_CONDITION.TITLE',
      icon: 'DELIVERY_CONDITION.ICON',
      description: 'DELIVERY_CONDITION.DESCRIPTION',
      permission: Permission.DELIVERY_CONDITIONS_LOAD,
      breadcrumb: {
        label: 'DELIVERY_CONDITION.TITLE'
      }
    }
  },
  {
    path: 'offer',
    loadChildren: () => import('./offer/offer.module').then(m => m.OfferModule),
    canLoad: [AuthenticationGuard, PermissionGuard],
    data: {
      title: 'OFFER.TITLE',
      icon: 'OFFER.ICON',
      description: 'OFFER.DESCRIPTION',
      permission: Permission.OFFERS_LOAD,
      breadcrumb: {
        label: 'OFFER.TITLE'
      }
    }
  },
  {
    path: 'order',
    loadChildren: () => import('./order/order.module').then(m => m.OrderModule),
    canLoad: [AuthenticationGuard, PermissionGuard],
    data: {
      title: 'ORDER.TITLE',
      icon: 'ORDER.ICON',
      description: 'ORDER.DESCRIPTION',
      permission: Permission.ORDERS_LOAD,
      breadcrumb: {
        label: 'ORDER.TITLE'
      }
    }
  },
  {
    path: 'delivery-note',
    loadChildren: () => import('./delivery-note/delivery-note.module').then(m => m.DeliveryNoteModule),
    canLoad: [AuthenticationGuard, PermissionGuard],
    data: {
      title: 'DELIVERY_NOTE.TITLE',
      icon: 'DELIVERY_NOTE.ICON',
      description: 'DELIVERY_NOTE.DESCRIPTION',
      permission: Permission.DELIVERY_NOTES_LOAD,
      breadcrumb: {
        label: 'DELIVERY_NOTE.TITLE'
      }
    }
  },
  {
    path: 'delivery-note-reply',
    loadChildren: () => import('./delivery-note-reply/delivery-note-reply.module').then(m => m.DeliveryNoteReplyModule),
    canLoad: [AuthenticationGuard],
    data: {
      title: 'DELIVERY_NOTE_REPLY.TITLE',
      icon: 'DELIVERY_NOTE_REPLY.ICON',
      description: 'DELIVERY_NOTE_REPLY.DESCRIPTION',
      breadcrumb: {
        label: 'DELIVERY_NOTE_REPLY.TITLE'
      }
    }
  },
  {
    path: 'invoice',
    loadChildren: () => import('./invoice/invoice.module').then(m => m.InvoiceModule),
    canLoad: [AuthenticationGuard, PermissionGuard],
    data: {
      title: 'INVOICE.TITLE',
      icon: 'INVOICE.ICON',
      description: 'INVOICE.DESCRIPTION',
      permission: Permission.INVOICES_LOAD,
      breadcrumb: {
        label: 'INVOICE.TITLE'
      }
    }
  },
  {
    path: 'credit-note',
    loadChildren: () => import('./credit-note/credit-note.module').then(m => m.CreditNoteModule),
    canLoad: [AuthenticationGuard, PermissionGuard],
    data: {
      title: 'CREDIT_NOTE.TITLE',
      icon: 'CREDIT_NOTE.ICON',
      description: 'CREDIT_NOTE.DESCRIPTION',
      permission: Permission.CREDIT_NOTES_LOAD,
      breadcrumb: {
        label: 'CREDIT_NOTE.TITLE'
      }
    }
  },
  {
    path: 'rap',
    loadChildren: () => import('./rap/rap.module').then(m => m.RapModule),
    // canLoad: [AuthenticationGuard, PermissionGuard],
    data: {
      title: 'RAP.TITLE',
      icon: 'RAP.ICON',
      description: 'RAP.DESCRIPTION',
      permission: Permission.RAP_LOAD,
      breadcrumb: {
        label: 'RAP.TITLE'
      }
    }
  },
  {
    path: 'payment-condition',
    loadChildren: () => import('./payment-condition/payment-condition.module').then(m => m.PaymentConditionModule),
    canLoad: [AuthenticationGuard, PermissionGuard],
    data: {
      title: 'PAYMENT_CONDITION.TITLE',
      icon: 'PAYMENT_CONDITION.ICON',
      description: 'PAYMENT_CONDITION.DESCRIPTION',
      permission: Permission.PAYMENT_CONDITIONS_LOAD,
      breadcrumb: {
        label: 'PAYMENT_CONDITION.TITLE'
      }
    }
  },
  {
    path: 'vehicle',
    loadChildren: () => import('./vehicle/vehicle.module').then(m => m.VehicleModule),
    canLoad: [AuthenticationGuard, PermissionGuard],
    data: {
      title: 'VEHICLE.TITLE',
      icon: 'VEHICLE.ICON',
      description: 'VEHICLE.DESCRIPTION',
      permission: Permission.VEHICLE_LOAD,
      breadcrumb: {
        label: 'VEHICLE.TITLE'
      }
    }
  },
  {
    path: 'vehicle-type',
    loadChildren: () => import('./vehicle-type/vehicle-type.module').then(m => m.VehicleTypeModule),
    canLoad: [AuthenticationGuard, PermissionGuard],
    data: {
      title: 'VEHICLE_TYPE.TITLE',
      icon: 'VEHICLE_TYPE.ICON',
      description: 'VEHICLE_TYPE.DESCRIPTION',
      permission: Permission.VEHICLE_TYPE_LOAD,
      breadcrumb: {
        label: 'VEHICLE_TYPE.TITLE'
      }
    }
  },
  {
    path: 'disposition',
    loadChildren: () => import('./disposition/disposition.module').then(m => m.DispositionModule),
    canLoad: [AuthenticationGuard, PermissionGuard],
    data: {
      title: 'DISPOSITION.TITLE',
      icon: 'DISPOSITION.ICON',
      description: 'DISPOSITION.DESCRIPTION',
      permissions: [
        Permission.DELIVERY_NOTE_ASSIGNMENT_LOAD,
        Permission.DELIVERY_NOTES_LOAD,
        Permission.VEHICLE_LOAD,
      ],
      breadcrumb: {
        label: 'DISPOSITION.TITLE'
      }
    }
  },
  {
    path: 'export',
    loadChildren: () => import('./export/export.module').then(m => m.ExportModule),
    canLoad: [AuthenticationGuard, PermissionGuard],
    data: {
      title: 'EXPORT.TITLE',
      icon: 'EXPORT.ICON',
      permission: ALL_EXPORT_PERMISSIONS,
      description: 'EXPORT.DESCRIPTION',
      breadcrumb: {
        label: 'EXPORT.LABEL'
      }
    }
  },
  {
    path: 'settings',
    loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule),
    canLoad: [AuthenticationGuard, PermissionGuard],
    data: {
      title: 'SETTINGS.TITLE',
      icon: 'SETTINGS.ICON',
      description: 'SETTINGS.DESCRIPTION',
      permission: Permission.APPLICATION_SETTINGS_LOAD,
      breadcrumb: {
        label: 'SETTINGS.TITLE'
      }
    }
  },
  {
    // if user navigates to 'login', go to correct login path
    path: 'login',
    pathMatch: 'full',
    redirectTo: 'auth/login'
  },
  {
    // if nothing given, go to dashboard
    path: '',
    pathMatch: 'full',
    redirectTo: 'dashboard'
  },
  {
    path: 'not-found',
    component: NotFoundComponent
  },
  {
    path: 'no-permissions',
    component: NoPermissionsComponent
  },
  {
    // this is a wildcard for all routes, that are not defined above
    path: '**',
    component: NotFoundComponent
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      APP_ROUTES,
      {
        anchorScrolling: 'enabled',
        scrollPositionRestoration: 'enabled',
        paramsInheritanceStrategy: 'always'
      }
    )
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
